$(document).ready(function () {
  // Section Feature Accordion Settings
  // feature 1
  $('#accordionOne').on('click', function () {
    $('.js-home-v2-feature-img').attr('src', 'assets/media/images/illustration/illustration4.svg');
  });

  // feature 2
  $('#accordionTwo').on('click', function () {
    $('.js-home-v2-feature-img').attr('src', 'assets/media/images/illustration/illustration-rev.svg');
  });

  // feature 3
  $('#accordionThree').on('click', function () {
    $('.js-home-v2-feature-img').attr('src', 'assets/media/images/illustration/illustration-rev2.svg');
  });

  // Accordion 1 Always Open
  let accordionArr = $('#accordionFeature').find('.accordion-collapse');
  console.log(accordionArr);

  function openFirst() {
    $('#collapseOne').addClass('show');
  }

  /* always keep at least 1 open by preventing the current to close itself */
  const accordions = document.querySelectorAll('.accordion-collapse');
  let opening = false;
  accordions.forEach(function (el) {
    el.addEventListener('hide.bs.collapse', (event) => {
      if (!opening) {
        event.preventDefault();
        event.stopPropagation();
      } else {
        opening = false;
      }
    });
    el.addEventListener('show.bs.collapse', (event) => {
      opening = true;
    });
  });
});
